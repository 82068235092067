export enum AlertActionType { // eslint-disable-line
  SET = 'set',
  FLUSH = 'flush'
}

export interface AlertState {
  message: string;
  show: boolean;
  color: string;
  duration: number;
}

export interface AlertAction {
  type: AlertActionType;
  payload?: AlertState;
}

const initialState: AlertState = {
  color: '',
  message: '',
  duration: 1500,
  show: false
};

const AlertReducer = (state: AlertState = initialState, action: AlertAction): AlertState => {
  switch (action.type) {

    case AlertActionType.SET: {
      return action.payload ? action.payload : state;
    }

    case AlertActionType.FLUSH: {
      return initialState;
    }

    default:
      return state;

  }
};

export default AlertReducer;
