import { IonContent, IonFab, IonFabButton, IonHeader, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent, IonList, IonPage, IonRefresher, IonRefresherContent, IonTitle, IonToolbar } from '@ionic/react';
import { qrCode } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import QRReader from '../../components/QRReader/QRReader';
import { TaskItemProps } from '../../components/Task/TaskItem';
import TaskList from '../../components/Task/TaskList';
import TaskItemSkeleton from '../../components/Task/TaskItemSkeleton';
import http from '../../http';
import Frame from '../../components/Layout/Frame';
import './Task.css';
import { AlertType } from '../../store/actions/alert_actions';
import useAlert from '../../hooks/alert';

interface Pagination {
  per_page: number;
  page: number;
  total_page: number;
  total_data: number;
}

const UUID_REGEX = /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;

const DEFAULT_PARAMS = {
  page: 1,
  per_page: 10,
  sort: 'status',
  status: '10,20,30'
};

const TaskPage: React.FC = () => {
  const infiniteScroll = useRef<any>(null);
  const [isLoading, setIsloading] = useState(true);
  const [tasks, setTasks] = useState<TaskItemProps[]>([]);
  const [pagination, setPagination] = useState<Pagination>({ per_page: 0, page: 0, total_data: 0, total_page: 0 });
  const [isScanning, setIsScanning] = useState(false);
  const [qrResult, setQrResult] = useState<string | null>(null);
  const { setAlert } = useAlert();

  /** on load */
  useEffect(() => {
    setIsloading(true);
    loadTasks();
  }, []);

  /** on qr */
  useEffect(() => {
    if (qrResult) {
      const ids = qrResult.split('||');
      if (ids.every(id => UUID_REGEX.test(id))) {
        upsertTask(ids);
      } else {
        setAlert('task invalid');
      }
    }
  }, [qrResult]);

  const loadTasks = async (page = 1, perPage = 10, appending = false) => {
    await http.get('/technician/tickets', { params: { ...DEFAULT_PARAMS, page, per_page: perPage } })
      .then(({ data: { data: tickets, pagination: meta } }) => {
        setTasks(appending ? [...tasks, ...tickets] : tickets);
        setPagination(meta);
        infiniteScroll.current.disabled = false;
      })
      .catch(() => setAlert('failed loading task'))
      .finally(() => setIsloading(false));
  };

  const upsertTask = async (ids: string[], status = 20) => {
    return http.put('/technician/tickets', { ticket_ids: ids, status })
      .then(() => setIsloading(true))
      .then(() => loadTasks())
      .then(() => { setAlert('task updated', AlertType.SUCCESS); })
      .catch(() => { setAlert('task invalid'); });
  };

  const toggleScanQR = () => {
    setQrResult(null);
    setIsScanning(!isScanning);
  };

  const onScanQR = (data: string | null): void => {
    if (data) {
      setQrResult(data);
      setIsScanning(false);
    }
  };

  const refreshPage = async (event: CustomEvent<any>): Promise<void> => {
    setIsloading(true);
    await loadTasks().then(() => {
      event.detail.complete();
      setIsloading(false);
    });
  };

  const loadMoreData = async (event: any): Promise<void> => {
    if (pagination.total_page > pagination.page) {
      await loadTasks(pagination.page + 1, pagination.per_page, true)
        .then(() => event.target.complete());
    } else {
      event.target.disabled = true;
    }
  };

  const qrReader = <QRReader onScan={onScanQR} />;

  const loadingSkeletons =
  <IonList>
    { [1, 2, 3, 4, 5, 6].map(i => <TaskItemSkeleton key={i} />) }
  </IonList>;

  const taskList = <TaskList items={tasks} onItemUpdate={upsertTask} />;

  const qrScanButton = <IonFab vertical="bottom" horizontal="end" slot="fixed">
    <IonFabButton>
      <IonIcon icon={qrCode} onClick={toggleScanQR} />
    </IonFabButton>
  </IonFab>;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Task</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonRefresher slot="fixed" onIonRefresh={refreshPage}>
          <IonRefresherContent />
        </IonRefresher>
        <Frame>
          { isScanning ? qrReader : isLoading ? loadingSkeletons : taskList }
        </Frame>
        { qrScanButton }
        <IonInfiniteScroll ref={infiniteScroll} onIonInfinite={loadMoreData}>
          <IonInfiniteScrollContent />
        </IonInfiniteScroll>
      </IonContent>
    </IonPage>
  );
};

export default TaskPage;
