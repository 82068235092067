
export enum AuthActionType { // eslint-disable-line
  LOGIN = 'login',
  LOGOUT = 'logout'
}

export interface AuthState {
  clearance: number;
  token: string | null;
  refresh_token: string | null;
  expires_at: Date | null;
}

export interface AuthAction {
  type: AuthActionType;
  payload?: any;
}

const initialState: AuthState = {
  clearance: 0,
  token: null,
  refresh_token: null,
  expires_at: null
};

const AuthReducer = (state: AuthState = initialState, action: AuthAction): AuthState => {

  switch (action.type) {
    case AuthActionType.LOGIN: {
      return {
        ...state,
        ...action.payload
      };
    }

    case AuthActionType.LOGOUT: {
      return initialState;
    }

    default:
      break;
  }

  return state;
};

export default AuthReducer;

