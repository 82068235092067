import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { store, persistor } from './store';

ReactDOM.render(<Provider store={store}>
  <PersistGate persistor={persistor} loading={null}>
    <App />
  </PersistGate>
</Provider>, document.getElementById('root'));

serviceWorker.register();
