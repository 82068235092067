import axios from 'axios';
import { store } from '../store';

const instance = axios.create({
  baseURL: process.env.REACT_APP_URL
});

instance.defaults.headers.post['Content-Type'] = 'application/json';

instance.interceptors.request.use(
  (req) => {
    const JWT_TOKEN = store.getState().auth.token;
    if (JWT_TOKEN) {
      req.headers.Authorization = `Bearer ${JWT_TOKEN}`;
    } else {
      req.headers.Authorization = `Basic ${process.env.REACT_APP_SECRET}`;
    }
    return req;
  },
  (err) => {
    // console.log(err);
    return Promise.reject(err);
  }
);

instance.interceptors.response.use(
  (res) => {
    // console.log(res);
    return res;
  },
  (err) => {
    // console.log(err);
    return Promise.reject(err);
  }
);

export default instance;
