import { AlertAction, AlertActionType } from '../reducers/alert_reducer';

export enum AlertType { // eslint-disable-line
  DANGER = 'danger',
  WARNING = 'warning',
  SUCCESS = 'success'
}

const DEFAULT_CONFIG = {
  duration: 1500,
  show: true,
  color: AlertType.DANGER
};

export const setAlert = (message: string, type: AlertType = AlertType.DANGER, duration: number = DEFAULT_CONFIG.duration): AlertAction => {
  return {
    type: AlertActionType.SET,
    payload: {
      ...DEFAULT_CONFIG,
      duration,
      color: type,
      message
    }
  };
};

export const dismissAlert = (): AlertAction => {
  return {
    type: AlertActionType.FLUSH,
  };
};

export default {
  setAlert,
  dismissAlert
};
