import moment from 'moment';
import http from '../../http';
import { Dispatch } from 'react';
import { AuthActionType, AuthAction } from '../reducers/auth_reducer';
import { CLEARANCE } from '../../utils/constant';
import { setAlert } from './alert_actions';
import { State } from '..';

type AuthDispatch = {
  type: AuthActionType;
  payload?: any;
}

export const login = (username: string, password: string, keep = true) => {
  return (dispatch: Dispatch<any>): void => {
    http.post('/auth/login', { username, password, keep }, { headers: { 'Content-Type': 'application/json' } })
      .then(({ data }) => {

        if (![CLEARANCE.TECHNICIAN, CLEARANCE.FINANCE].includes(data.clearance)) {
          dispatch(setAlert('unauthorized else than technician'));
        }

        /** auto relogin */
        dispatch(authTimeout(data.expires_in * 1000));

        /** set tokens */
        const expiresAt = moment().add(data.expires_in, 'seconds').format();
        dispatch({ type: AuthActionType.LOGIN, payload: { token: data.token, refresh_token: data.refresh_token, clearance: data.clearance, expires_at: expiresAt } });
      })
      .catch(() => {
        dispatch(setAlert('username or password incorrect'));
      });
  };
};

export const reLogin = (dispatch: Dispatch<AuthDispatch>, getState: () => State): void => {
  console.log(`REFERSHING TOKEN: ${new Date().toISOString()}`); // eslint-disable-line

  const refreshToken = getState().auth.refresh_token;
  if (refreshToken) {
    http.post('/auth/refresh', { refresh_token: refreshToken }, { headers: { 'Content-Type': 'application/json' } })
      .then((result) => {
        const { data: { token, expires_in: expiresIn } } = result;

        const now = moment().format();
        const expiresAt = moment().add(expiresIn, 'seconds').format();
        const diff = moment(expiresAt).diff(moment(now), 'seconds') * 1000;

        dispatch(authTimeout(diff));
        dispatch({ type: AuthActionType.LOGIN, payload: { token, expires_at: expiresAt } });
      });
  } else {
    dispatch(logout());
  }
};

export const authTimeout = (diff: number): any => {
  return (dispatch: Dispatch<any>) => {
    setTimeout(() => {
      dispatch(reLogin);
    }, diff);
  };
};

export const logout = (): AuthAction => ({
  type: AuthActionType.LOGOUT
});

export default {
  reLogin
};
