import { IonApp, IonIcon, IonLabel, IonProgressBar, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs, IonToast } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/typography.css';

import { list, person } from 'ionicons/icons';
import moment from 'moment';
import React, { lazy, Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import useAlert from './hooks/alert';
import ProfilePage from './pages/Profile/Profile';
import TaskPage from './pages/Task/Task';
import { State } from './store';
import { dismissAlert } from './store/actions/alert_actions';
import { authTimeout, reLogin } from './store/actions/auth_actions';
import './theme/variables.css';

const LoginPage = lazy(() => import('./pages/Login/Login'));
const SearchPage = lazy(() => import('./pages/Search/Search'));

const App: React.FC = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector<State, boolean>(state => !!state.auth.token);
  const expiresAt = useSelector<State, Date | null>(state => state.auth.expires_at);
  const { alert } = useAlert();

  /** set auth fallback */
  useEffect(() => {
    if (isAuthenticated) {
      const expiresIn = moment(expiresAt).diff(moment(), 'milliseconds');
      dispatch(expiresIn < 0 ? reLogin : authTimeout(expiresIn));
    }
  }, []);

  const MainRoutes =
    <IonReactRouter>
      <IonTabs>
        <IonRouterOutlet>
          <Route path="/task" component={TaskPage} exact={true} />
          <Route path="/profile" component={ProfilePage} exact={true} />
          <Redirect to="/task" />
        </IonRouterOutlet>
        <IonTabBar slot="bottom">
          <IonTabButton tab="task" href="/task">
            <IonIcon icon={list} />
            <IonLabel>Task</IonLabel>
          </IonTabButton>
          <IonTabButton tab="profile" href="/profile">
            <IonIcon icon={person} />
            <IonLabel>Profile</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </IonReactRouter>;

  const PublicRoutes =
    <IonReactRouter>
      <IonRouterOutlet>
        <Route path="/search" component={SearchPage} exact={true} />
        <Route path="/search/:id" component={SearchPage} exact={true} />
        <Route path="/login" component={LoginPage} exact={true} />
        <Redirect to="/search" />
      </IonRouterOutlet>
    </IonReactRouter>;

  const fallback = <IonProgressBar type="indeterminate" />;

  const toast = <IonToast
    isOpen={alert.show}
    message={alert.message}
    color={alert.color}
    duration={alert.duration}
    onDidDismiss={() => dispatch(dismissAlert())}
    position="bottom"
  />;

  return (
    <IonApp>
      <Suspense fallback={fallback}>
        { isAuthenticated ? MainRoutes : PublicRoutes }
        { toast }
      </Suspense>
    </IonApp>
  );
};

export default App;
