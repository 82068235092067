import { IonList } from '@ionic/react';
import React from 'react';
import TaskItem, { TaskItemProps } from './TaskItem';

interface TaskListProps {
  onItemUpdate(ids: string[], status: number): Promise<void>;
  items: TaskItemProps[];
}

const TaskList: React.FC<TaskListProps> = (props) => {

  const taskItems = props.items.map(task => <TaskItem
    key={'item-' + task.id}
    id={task.id}
    item={task.item}
    item_detail={task.item_detail}
    treatment={task.treatment}
    status={task.status}
    onItemUpdate={props.onItemUpdate}
  />);

  return (
    <IonList>
      { taskItems }
    </IonList>
  );
};

export default TaskList;
