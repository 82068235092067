import { IonBadge, IonButton, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonText } from '@ionic/react';
import React, { useRef } from 'react';
import { INVOICE_STATUS_NAME, STATUS_CODE, STATUS_COLOR } from '../../utils/constant';

export interface TaskItemProps {
  id: string;
  item: string;
  item_detail: string;
  treatment: string;
  status: number;
  onItemUpdate(ids: string[], status: number): Promise<void>;
}

const TaskItem: React.FC<TaskItemProps> = (props) => {
  const ref = useRef<any>(null);

  const determineSlider = (status: number) => {
    let next = STATUS_CODE.ONGOING;
    let color = '';
    let text = '';

    switch (status) {
      case STATUS_CODE.QUEUE:
        next = STATUS_CODE.ONGOING;
        color = STATUS_COLOR[STATUS_CODE.ONGOING];
        text = INVOICE_STATUS_NAME[STATUS_CODE.ONGOING];
        break;

      case STATUS_CODE.ONGOING:
        next = STATUS_CODE.FINISH;
        color = STATUS_COLOR[STATUS_CODE.FINISH];
        text = INVOICE_STATUS_NAME[STATUS_CODE.FINISH];
        break;

      default:
        break;
    }

    return <IonItemOption color={color} >
      <IonButton color={color} onClick={() => {  ref.current?.closeOpened(); props.onItemUpdate([props.id], next); }}>{ text }</IonButton>
    </IonItemOption>;
  };

  return (
    <IonItemSliding ref={ref}>
      <IonItem>
        <IonLabel>
          <IonText color="dark"><h3><b>{ props.item }</b></h3></IonText>
          <IonText color="tertiary"><h3>{ props.treatment }</h3></IonText>
          <h3 style={{ color: 'gray' }}>{ props.item_detail }</h3>
        </IonLabel>
        <IonBadge color={STATUS_COLOR[props.status]} slot="end">{ INVOICE_STATUS_NAME[props.status] }</IonBadge>
        { [STATUS_CODE.QUEUE, STATUS_CODE.ONGOING].includes(props.status) ? <IonItemOptions side="end">{ determineSlider(props.status) }</IonItemOptions> : null }
      </IonItem>
    </IonItemSliding>
  );
};

export default TaskItem;
