import { applyMiddleware, combineReducers, createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import storage from 'redux-persist/lib/storage';
import AuthReducer, { AuthState } from './reducers/auth_reducer';
import AlertReducer, { AlertState } from './reducers/alert_reducer';

export type State = {
  auth: AuthState;
  alert: AlertState;
}

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, combineReducers({ auth: AuthReducer, alert: AlertReducer }));

const composedEnhancer = composeWithDevTools(applyMiddleware(thunkMiddleware));

export const store = createStore(persistedReducer, composedEnhancer);

export const persistor = persistStore(store as any);

export default {
  store, persistor
};
