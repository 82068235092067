import { IonAlert, IonBadge, IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonModal, IonNote, IonPage, IonRefresher, IonRefresherContent, IonSkeletonText, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { barcode, checkmark, home, person } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Frame from '../../components/Layout/Frame';
import useAlert from '../../hooks/alert';
import http from '../../http';
import { AlertType } from '../../store/actions/alert_actions';
import { logout } from '../../store/actions/auth_actions';

interface ProfileData {
  name: string;
  branch: string;
  code: string;
  total: number;
}

const ProfilePage: React.FC = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsloading] = useState(true);
  const [profile, setProfile] = useState<ProfileData>({ name: '', branch: '', code: '', total: 0 });
  const [profileUpdate, setProfileUpdate] = useState<{ new_password: string; old_password: string;}>({ new_password: '', old_password: '' });
  const { setAlert, alert } = useAlert();
  const dispatch = useDispatch();

  /** on page load */
  useEffect(() => {
    loadProfile();
  }, []);

  const loadProfile = async () => {
    setIsloading(true);
    await http.get('/technician/profile')
      .then(({ data }) => {
        setProfile({
          name: data.technichian.name,
          branch: data.technichian.branch.name,
          code: data.technichian.code,
          total: data.stats.tickets_completed
        });
      })
      .catch(() => { setAlert('failed loading profile'); })
      .finally(() =>  setIsloading(false));
  };

  const refreshPage = async (event: CustomEvent<any>): Promise<void> => {
    await loadProfile().then(() => event.detail.complete());
  };

  const onLogout = (): void => {
    dispatch(logout());
  };

  const onUpdateProfile = async () => {
    await http.put('/technician/profile', profileUpdate)
      .then(() => {
        setShowModal(false);
        setProfileUpdate({ new_password: '', old_password: '' });
        setAlert('profile updated', AlertType.SUCCESS);
      })
      .catch((err) => {
        setAlert(err.response?.data?.error_data?.new_password ||
          err.response?.data?.error_message ||
          'failed updating profile');
      });
  };

  const loadingSkeleton = <IonSkeletonText animated />;

  const settingModal =
    <IonModal isOpen={showModal} >
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>update profile</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => setShowModal(false)}>cancel</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonList lines="full">
          <IonItem>
            <IonLabel position="stacked">old password</IonLabel>
            <IonInput type="password" value={profileUpdate.old_password} onIonChange={e => setProfileUpdate({ ...profileUpdate, old_password: e.detail.value || '' })} clearInput />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">new password</IonLabel>
            <IonInput type="password" value={profileUpdate.new_password} onIonChange={e => setProfileUpdate({ ...profileUpdate, new_password: e.detail.value || '' })} clearInput />
          </IonItem>
        </IonList>
      </IonContent>
      <IonFooter>
        <IonButton disabled={!(profileUpdate.new_password && profileUpdate.old_password) || alert.show} color="primary" expand="full" onClick={onUpdateProfile} >save</IonButton>
      </IonFooter>
    </IonModal>;

  const logoutAlert = <IonAlert
    isOpen={showAlert}
    message={'Are you sure want to logout ?'}
    buttons={[
      {
        text: 'Cancel',
        role: 'cancel',
        handler: () => setShowAlert(false)
      },
      {
        text: 'Yes',
        handler: onLogout
      }
    ]}
  />;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Profile</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonRefresher slot="fixed" onIonRefresh={refreshPage}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <Frame>
          <IonList>
            <IonItem>
              <IonLabel>{ isLoading ? loadingSkeleton : profile.branch }</IonLabel>
              <IonNote slot="start"><IonIcon icon={home} /></IonNote>
            </IonItem>
            <IonItem>
              <IonLabel>{ isLoading ? loadingSkeleton : profile.name }</IonLabel>
              <IonNote slot="start"><IonIcon icon={person} /></IonNote>
            </IonItem>
            <IonItem>
              <IonLabel>{ isLoading ? loadingSkeleton : profile.code }</IonLabel>
              <IonNote slot="start"><IonIcon icon={barcode} /></IonNote>
            </IonItem>
            <IonItem>
              <IonLabel>{ isLoading ? loadingSkeleton : <IonBadge color="success">{ profile.total }</IonBadge> }</IonLabel>
              <IonNote slot="start"><IonIcon icon={checkmark} /></IonNote>
            </IonItem>
            <br />
            <IonItem onClick={() => setShowModal(true)}>
              <IonText color="dark">
                Setting
              </IonText>
            </IonItem>
            <IonItem onClick={() => setShowAlert(true)}>
              <IonText color="dark">
                Logout
              </IonText>
            </IonItem>
          </IonList>
          { settingModal }
          { logoutAlert }
        </Frame>
      </IonContent>
    </IonPage>
  );
};

export default ProfilePage;
