import { IonItem, IonLabel, IonSkeletonText } from '@ionic/react';
import React from 'react';

const TaskItemSkeleton: React.FC = () => {
  return <IonItem>
    <IonLabel>
      <h2><IonSkeletonText animated style={{ width: '60%' }} /></h2>
      <p><b><IonSkeletonText animated style={{ width: '30%' }}  /></b></p>
      <p><IonSkeletonText animated style={{ width: '80%' }} /></p>
    </IonLabel>
    <IonSkeletonText animated style={{ width: '13%' }} />
  </IonItem>;
};

export default TaskItemSkeleton;
