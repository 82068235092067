import { useDispatch, useSelector } from 'react-redux';
import { State } from '../store';
import { AlertType, setAlert as setAlertAction } from '../store/actions/alert_actions';
import { AlertState } from '../store/reducers/alert_reducer';

const useAlert = (): { alert: AlertState; setAlert(message: string, type?: AlertType): void; } => {
  const dispatch = useDispatch();
  const alert = useSelector<State, AlertState>(state => state.alert);

  const setAlert = (message: string, type: AlertType = AlertType.DANGER): void => {
    dispatch(setAlertAction(message, type));
  };

  return {
    alert,
    setAlert
  };
};

export default useAlert;
