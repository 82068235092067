import { IonCard } from '@ionic/react';
import React from 'react';
import QrReader from 'react-qr-reader';

interface Props {
  onScan(data: string | null): void;
}

const QRReader: React.FC<Props> = (props): any => {

  const handleError = (err: Error): void => {
    console.error(err);
  };

  return (
    <IonCard>
      <QrReader
        delay={500}
        onError={handleError}
        onScan={props.onScan}
        style={{ width: '100%' }}
      />
    </IonCard>
  );
};

export default QRReader;
