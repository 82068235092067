export const CLEARANCE = {
  SUPER_ADMIN: 10,
  BRANCH_ADMIN: 4,
  FINANCE: 3,
  TECHNICIAN: 2,
  BLOCKED: 0
};

export const CLEARANCE_NAME = {
  10: 'Super Admin',
  4: 'Branch Admin',
  3: 'Finance',
  2: 'Technician',
  0: 'Blocked'
};

export const STATUS_CODE = {
  QUEUE: 10,
  ONGOING: 20,
  FINISH: 30,
  OUT: 35
};

export const INVOICE_STATUS_NAME = {
  [STATUS_CODE.QUEUE]: 'Queue',
  [STATUS_CODE.ONGOING]: 'Ongoing',
  [STATUS_CODE.FINISH]: 'Finish',
  [STATUS_CODE.OUT]: 'Out'
};

export const INVOICE_STATUS_NAME_ID = {
  [STATUS_CODE.QUEUE]: 'Menunggu',
  [STATUS_CODE.ONGOING]: 'Dikerjakan',
  [STATUS_CODE.FINISH]: 'Selesai',
  [STATUS_CODE.OUT]: 'Diambil'
};

export const STATUS_COLOR = {
  [STATUS_CODE.QUEUE]: 'tertiary',
  [STATUS_CODE.ONGOING]: 'warning',
  [STATUS_CODE.FINISH]: 'success',
  [STATUS_CODE.OUT]: 'medium'
};

export default {
  CLEARANCE
};

